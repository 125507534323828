import { graphql } from "gatsby"
import Layout, { IMetadata } from "../components/Layout"
import React from "react"
import Section from "../components/ui/Section/Section";
import Blocks from "../components/blocks/Blocks";
import * as styles from './leistungen.module.scss';

export default ({ data }) => {
    const material: any = data.materialienHJson

    const crumbs = [
        { label: "Home", url: "" },
        { label: "Leistungen", url: "leistungen" },
        { label: "Materialien", url: "materialien" },
        { label: material.name, url: material.url },
    ];

    // Create metadata object
    const metaData: IMetadata = {};
    metaData.description = material.description;
    metaData.ogImage = material.listenbild.src.childImageSharp.gatsbyImageData.images.fallback.src;
    metaData.ogTitle = material.name + " bei HIS Print & Service";

    return (
        <Layout crumbs={crumbs} hasWave={true} headerColor={"dark"} metadatas={metaData}>
            <Section size="extraSmall" className={styles.headlineWrapper}>
                <h1>{material.name}</h1>
                <p className="subtitle">{material.description}</p>
                {material.text && <p>{material.text}</p>}
                
            </Section>
           <Blocks blocks={material.blocks} />
        </Layout>
    )
}

export const materialQuery = graphql`query MaterialQuery($url: String!) {
  materialienHJson(url: {eq: $url}) {
    name
    type
    url
    listenbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    blocks {
      ...BlocksFragment
    }
    description
  }
}
`
